import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const Image = styled.img`
  width: 330px;
  max-width: 100%;
  height: 230px;
  object-fit: cover;
  border-radius: 30px;
  border: 4px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${media.desktop(css`
    grid-template-columns: repeat(3, 1fr);
  `)}
`;

export const GalleryImage = styled.img`
  width: 100%;
  max-height: 330px;
  border-radius: 10px;
  object-fit: cover;
`;

export const HorsePriceImage = styled.img`
  width: 23px;
  height: 23px;
  object-fit: contain;
`;

export const HorseSexIcon = styled.img`
  width: 23px;
  height: 23px;
  object-fit: contain;
`;

export const BackToCatalog = styled.a`
  display: inline-block;
`;
