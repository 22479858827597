import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const Image = styled.img`
  width: 100%;
  height: 40vh;
  object-fit: cover;
`;

export const Link = styled.a`
  display: inline-block;

  font-size: 16px;
  color: rgb(138, 23, 26);
  transition: padding 0.3s ease-in-out;

  &:hover {
    padding-left: 8px;
  }
`;

export const ButtonLink = styled.a`
  display: inline-block;
  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.1);
  }
`;
