import React from "react";
import { Stack, Text, Separator } from "@kiwicom/orbit-components";

// Assets
import LogoEinfach from "../../assets/images/footer-einfach.png";
import LogoRepnik from "../../assets/images/footer-repnik.png";
import LogoTmbisbarn from "../../assets/images/footer-tmbinsbarn.png";
// Utils
// import translate from "../../utils/translate";
// Components
import { Container } from "../Content/styles";

// Styles
import { Wrapper, LogoWrapper, Logo, LogoLink } from "./Styled";

const Footer = () => {
  return (
    <Wrapper>
      <Container>
        <LogoWrapper>
          <Stack
            direction="row"
            spacing="XXLarge"
            justify="center"
            align="center"
            wrap
          >
            <LogoLink href="/">
              <Logo src={LogoEinfach} alt="Einfach" />
            </LogoLink>
            <LogoLink href="/">
              <Logo src={LogoRepnik} alt="Repnik" />
            </LogoLink>
            <LogoLink href="/">
              <Logo src={LogoTmbisbarn} alt="Tmbisbarn" />
            </LogoLink>
          </Stack>
        </LogoWrapper>
        <Separator spaceAfter="small" />
        <Stack justify="between" wrap>
          <Text type="white">
            © {new Date().getFullYear()} Hof der Lipizzaner
          </Text>
          <Text type="white">BIŠ 40, 2254 TRNOVSKA VAS, SLOWENIEN</Text>
        </Stack>
      </Container>
    </Wrapper>
  );
};

export default Footer;
