import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const BlogLink = styled.a`
  &:hover {
    h3 {
      color: rgb(138, 23, 26) !important;
    }
  }
`;

export const ContentImage = styled.img`
  width: 100%;
  min-height: 400px;
  object-fit: cover;
  margin: 16px 16px 0 0;

  border-radius: 10px;
  border: 4px solid rgb(138, 23, 26);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);

  &:first-child {
    margin-top: 0px;
  }
`;

export const BackToCatalog = styled.a`
  display: inline-block;
`;
