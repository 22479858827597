import styled from "styled-components";

// Animations
import { animateContent } from "../../utils/animations/keyframes";

export const Wrapper = styled.div`
  width: 100%;
  margin-top: 80px;
  background-color: #ecf0f1;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
`;

export const Container = styled.div<{
  directionRow?: boolean;
  spaceBetween?: boolean;
  animate?: boolean;
}>`
  width: 100%;
  max-width: 1100px;
  margin: auto;
  display: flex;
  flex-direction: ${({ directionRow }) => (directionRow ? "row" : "column")};
  justify-content: ${({ spaceBetween }) =>
    spaceBetween ? "space-between" : "unset"};

  animation-name: ${({ animate }) => (animate ? animateContent : "unset")};
  animation-duration: ${({ animate }) => (animate ? "0.6s" : "unset")};
`;
