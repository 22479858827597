import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

// Animations
import { animateMainMenu } from "../../utils/animations/keyframes";

export const Wrapper = styled.nav`
  width: 100%;
  height: 80px;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px;
  /* background-color: rgb(138, 23, 26); */
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 10;

  animation-name: ${animateMainMenu};
  animation-duration: 0.6s;
`;

export const Menu = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  display: flex;
  top: 0;
  left: 0;
  justify-content: center;
  align-items: center;
  background-color: #8a171a;
  z-index: 11;
  overflow-y: auto;

  animation-name: ${animateMainMenu};
  animation-duration: 0.6s;

  ul {
    display: inline-flex;
    flex-direction: column;
    margin: auto;
    color: white;
    padding: 24px 0;

    li {
      line-height: 2;
      font-size: 20px;
      font-weight: 700;
      color: white;
      text-align: center;
      position: relative;

      ${media.tablet(css`
        font-size: 30px;
        line-height: 1.8;
      `)}

      a {
        width: 100%;
        height: 100%;
        display: block;
        padding: 0 30px;
      }

      &:hover {
        border-radius: 20px;
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
`;

export const Branding = styled.div`
  display: flex;
  height: 50px;

  img {
    height: 50px;
  }
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
`;

export const Close = styled.div`
  display: flex;
  position: fixed;
  top: 24px;
  right: 40px;
  width: 30px;
  height: 30px;
  cursor: pointer;
`;

export const MenuButton = styled.div`
  margin-left: 8px;
  cursor: pointer;

  img {
    width: 20px;
    height: 20px;
    object-fit: contain;
    background-color: transparent;
  }
`;

export const CatalogButton = styled.button`
  display: inline-block;
  height: 40px;
  padding: 0 20px;
  margin-right: 30px;
  background-color: white;
  /* border: 1px solid #ef9f4a; */
  border-radius: 3px;
  color: #8a171a;
  cursor: pointer;

  &:hover {
    background-color: #ef9f4a;
  }
`;
