import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  align-self: center;
  display: flex;
  z-index: 3;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const Cards = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardOverlay = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0 32px;

  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 24px;
  font-weight: 700;

  ${media.tablet(css`
    font-size: 32px;
    padding: 0 64px;
  `)}
`;

export const Overlay = styled.div`
  width: 100%;
  height: calc(80vh - 80px);
  margin-top: calc(-80vh + 80px);
  display: flex;
  padding: 0 16px;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;

  img {
    display: inline-block;
    width: 200px;
    height: 200px;
    background-color: transparent;

    ${media.tablet(css`
      width: 280px;
      height: 280px;
    `)}
  }
`;

export const CarouselItem = styled.div<{
  backgroundImage: unknown;
}>`
  width: 100%;
  height: calc(80vh - 80px);
  display: flex;
  justify-content: center;
  align-items: center;
  /* @ts-ignore */
  background-image: url(${({ backgroundImage }: any) => backgroundImage});
  background-size: cover;
  background-position: center;
  z-index: 1;
`;

export const Card = styled.div<{
  backgroundImage: unknown;
}>`
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  background-image: url(${({ backgroundImage }: any) => backgroundImage});
  background-size: cover;
  background-position: center;
  border-radius: 0;
  transition: transform 0.3s ease-in-out;
  overflow: hidden;

  ${media.tablet(css`
    border-radius: 10px;
  `)}

  &:hover {
    transform: scaleX(1.05);
  }

  &:not(:first-child) {
    margin-top: 16px;
  }

  &:nth-child(2) {
    height: 300px;
  }
`;
