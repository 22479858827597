import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import {
  Card as WhiteCard,
  CardSection,
  Stack,
  Box,
  Button,
  Text,
  Heading,
  Loading,
} from "@kiwicom/orbit-components";

// Intro
import HorseIcon from "../../assets/icons/horse-head.png";
// Utils
import { getHorses } from "../../utils/api/horse";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Types
import { Horse as HorseType } from "../../static/types/horse";
// Utils
import translate from "../../utils/translate";

// Styles
import { Content, HorsePriceImage, Cards, Card, CardOverlay } from "./Styled";

const Catalog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [horses, setHorses] = useState<HorseType[]>([]);

  useEffect(() => {
    // Fetchs horses
    getHorses(setHorses, setLoading);
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{translate("navigation.menu.catalog")}</title>
      </Helmet>
      <Content>
        <Container animate>
          <Stack spacing="XLarge">
            <WhiteCard>
              <CardSection>
                <Stack>
                  <Text>
                    <strong>Noblesse, Adel und Charakter</strong> <br /> Bei uns
                    können Sie Lipizzaner kennenlernen, sich ausgiebig über
                    diese Rasse informieren und sich ihren Traum vom eigenen
                    Lipizzaner erfüllen. Alle aktuellen{" "}
                    <strong>LIPIZZANER ZUM VERKAUF</strong> finden sie hier:
                  </Text>
                  <a
                    href={`${process.env.REACT_APP_API_BASE}/docs/ankaufsberatung.pdf`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <Button>{translate("catalog.purchase_advice")}</Button>
                  </a>
                </Stack>
              </CardSection>
            </WhiteCard>
            <Box
              padding={{ left: "medium", right: "medium" }}
              tablet={{ padding: { left: "none", right: "none" } }}
            >
              <Stack spacing="XLarge">
                {/* <Stack direction="row" spacing="small">
                  <InputField placeholder="Search..." />
                  <Button>Search</Button>
                </Stack> */}
                {loading && <Loading type="pageLoader" />}
                {!loading && horses.length && (
                  <Cards>
                    {horses.map((horse: HorseType) => (
                      <Card
                        backgroundImage={`${process.env.REACT_APP_STORAGE_BASE}/horse-thumb/${horse.image}`}
                      >
                        <CardOverlay href={`/catalog/${horse.id}`}>
                          <Stack>
                            <Heading type="title2" inverted>
                              {horse.title}
                            </Heading>
                            <Button size="small">
                              {translate("global.see_more")}
                            </Button>
                          </Stack>
                        </CardOverlay>
                      </Card>
                    ))}
                  </Cards>
                )}
              </Stack>
            </Box>
            <WhiteCard>
              <CardSection>
                <Stack>
                  <Stack
                    basis="100%"
                    direction="row"
                    justify="between"
                    align="center"
                  >
                    <Stack direction="row" inline>
                      <HorsePriceImage src={HorseIcon} />
                    </Stack>
                    <Stack
                      direction="row"
                      align="center"
                      grow={false}
                      spacing="XSmall"
                      inline
                    >
                      <Text type="secondary" size="small">
                        {translate("catalog.price.bis")}
                      </Text>
                      <Text weight="bold">3000€</Text>
                    </Stack>
                  </Stack>
                  <Stack direction="row" justify="between" align="center">
                    <Stack direction="row" inline>
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                    </Stack>
                    <Stack
                      direction="row"
                      align="center"
                      grow={false}
                      spacing="XSmall"
                      inline
                    >
                      <Text type="secondary" size="small">
                        {translate("catalog.price.von")}
                      </Text>
                      <Text weight="bold">3000€ - 5000€</Text>
                    </Stack>
                  </Stack>
                  <Stack direction="row" justify="between" align="center">
                    <Stack direction="row" inline>
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                    </Stack>
                    <Stack
                      direction="row"
                      align="center"
                      grow={false}
                      spacing="XSmall"
                      inline
                    >
                      <Text type="secondary" size="small">
                        {translate("catalog.price.von")}
                      </Text>
                      <Text weight="bold">5000€ - 7000€</Text>
                    </Stack>
                  </Stack>
                  <Stack direction="row" justify="between" align="center">
                    <Stack direction="row" inline>
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                    </Stack>
                    <Stack
                      direction="row"
                      align="center"
                      grow={false}
                      spacing="XSmall"
                      inline
                    >
                      <Text type="secondary" size="small">
                        {translate("catalog.price.von")}
                      </Text>
                      <Text weight="bold">7000€ - 10000€</Text>
                    </Stack>
                  </Stack>
                  <Stack direction="row" justify="between" align="center">
                    <Stack direction="row" inline>
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                      <HorsePriceImage src={HorseIcon} />
                    </Stack>
                    <Stack
                      direction="row"
                      align="center"
                      grow={false}
                      spacing="XSmall"
                      inline
                    >
                      <Text type="secondary" size="small">
                        {translate("catalog.price.ab")}
                      </Text>
                      <Text weight="bold">10000€</Text>
                    </Stack>
                  </Stack>
                </Stack>
              </CardSection>
            </WhiteCard>
          </Stack>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default Catalog;
