import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  padding: 48px 0;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const Image = styled.img`
  width: 120px;
  height: 120px;
  object-fit: cover;
  border-radius: 50%;
  border: 4px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
`;

export const Icon = styled.img`
  width: 30px;
  height: 30px;
`;

export const GoogleMap = styled.iframe`
  width: 100%;
  height: 400px;
  border: 4px solid white;
  border-radius: 0;

  ${media.tablet(css`
    border-radius: 10px;
  `)}
`;
