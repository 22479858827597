import React, { useState, useEffect } from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardSection,
  Stack,
  Text,
  Heading,
  Button,
  Loading,
} from "@kiwicom/orbit-components";

import HeroImage from "../../assets/images/description-hero.jpg";
// Utils
import { getRepnikHorses } from "../../utils/api/horse";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
import { Card as CardItem, Cards, CardOverlay } from "../Catalog/Styled";
// Type
import { Horse as HorseType } from "../../static/types/horse";
// Utils
import translate from "../../utils/translate";

// Styles
import { Content, Image, Link, ContentImage } from "./Styled";

const Repnik = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [horses, setHorses] = useState<HorseType[]>([]);

  useEffect(() => {
    // Fetchs horses
    getRepnikHorses(setHorses, setLoading);
  }, []);

  return (
    <Wrapper>
      <Helmet>
        <title>{translate("navigation.menu.fahrstall_repnik")}</title>
      </Helmet>
      <Image
        src={`${process.env.REACT_APP_API_BASE}/images/fahrstall-repnik-banner.jpg`}
      />
      <Content>
        <Container animate>
          <Stack>
            <Card>
              <CardSection>
                <Stack>
                  <Text size="large">
                    <strong>Primoz Repnik</strong> ist ein leidenschaftlicher
                    Kutschfahrer und mein persönlicher Fahrtrainer.
                  </Text>
                  <Text>
                    Das Fahren wurde ihm bereits in die Wiege gelegt, bereits
                    sein Vater, wie auch sein Großvater waren begeisterte
                    Pferdemenschen und das Kutschefahren ist fest mit der
                    Familientradition der Familie REPNIK verbunden. <br />{" "}
                    <br />
                    Seit drei Generationen werden hier auf dem Hof REPNIK
                    Schwere Warmblütler gefahren und stets selbst ausgebildet.{" "}
                    <br /> <br />
                    Aktuelle Verkaufspferde aus dem Hause REPNIK finden sie
                    hier:
                  </Text>
                </Stack>
              </CardSection>
              {loading && (
                <CardSection>
                  <Loading type="pageLoader" />
                </CardSection>
              )}
              {!loading && horses && horses.length > 0 && (
                <CardSection>
                  <Cards>
                    {horses.map((horse: HorseType) => (
                      <CardItem
                        backgroundImage={`${process.env.REACT_APP_STORAGE_BASE}/horse-thumb/${horse.image}`}
                      >
                        <CardOverlay href={`catalog/${horse.id}`}>
                          <Stack>
                            <Heading type="title2" inverted>
                              {horse.title}
                            </Heading>
                            <Button size="small">
                              {translate("global.see_more")}
                            </Button>
                          </Stack>
                        </CardOverlay>
                      </CardItem>
                    ))}
                  </Cards>
                </CardSection>
              )}
              <CardSection>
                <Stack>
                  <Text>
                    Da der Hof REPNIK über eine eigene Pension verfügt, können
                    sie auch gerne übernachten und sich in Ruhe alle Pferde
                    anschauen...sie können eine Kutschfahrt machen, sie können
                    Reiten und sich mit Bedacht entscheiden, ob ein “Schwerer”
                    ihr neuer Lebenspartner werden wird. <br /> <br />
                    Standort: 1241 Kamnik (80km von Villach, 20 km von
                    Ljubljana) <br /> <br />
                    Auch hier stehe ich allen Kaufinteressenten stets zur Seite.{" "}
                    <br />
                    {/* TODO: fix this green link shit */}
                    <a
                      href={`${process.env.REACT_APP_API_BASE}/docs/ankaufsberatung.pdf`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Nutzen Sie MEINE INDIVIDUELLE ANKAUFSBERATUNG
                    </a>
                  </Text>
                  <Stack direction="row">
                    <Link target="_blank" href="https://www.gostilna-repnik.si">
                      gostilna-repnik.si
                    </Link>
                    <Text> · </Text>
                    <Link target="_blank" href="https://www.vprege-repnik.si">
                      vprege-repnik.si
                    </Link>
                  </Stack>
                </Stack>
              </CardSection>
            </Card>
            <Card>
              <CardSection>
                <ContentImage
                  src={`${process.env.REACT_APP_API_BASE}/images/fahrstall-repnik-image-1.jpg`}
                />
                <ContentImage
                  src={`${process.env.REACT_APP_API_BASE}/images/fahrstall-repnik-image-2.jpg`}
                />
                <ContentImage
                  src={`${process.env.REACT_APP_API_BASE}/images/fahrstall-repnik-image-3.jpg`}
                />
                <ContentImage
                  src={`${process.env.REACT_APP_API_BASE}/images/fahrstall-repnik-image-4.jpg`}
                />
              </CardSection>
            </Card>
          </Stack>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default Repnik;
