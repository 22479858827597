// Extract youtube v parameter value

function getYoutubeVideoParam(url: string) {
  const splitUrl = url.split(/[?&\s]/);
  const params: any = splitUrl
    .map((param: string) => {
      const paramName = param.substring(0, 2);
      const paramValue = param.slice(2);

      if (paramName === "v=") {
        return {
          v: paramValue,
        };
      }

      return null;
    })
    .filter(Boolean);

  return params[0]?.v;
}

export default getYoutubeVideoParam;
