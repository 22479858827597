import styled from "styled-components";

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 24px;
`;

export const Flag = styled.img`
  width: 30px;
  height: 30px;
  display: inline-block;
  cursor: pointer;
  transition: 0.3s ease-in-out;

  &:not(:last-child) {
    margin-right: 10px;
  }

  &:hover {
    transform: translateY(-3px);
  }
`;
