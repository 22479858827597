import React from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardSection,
  Stack,
  Text,
  List,
  ListItem,
  Heading,
} from "@kiwicom/orbit-components";

import HeroImage from "../../assets/images/the-story-hero.jpg";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import translate from "../../utils/translate";

// Styles
import { Content, Image, Link } from "./Styled";

const TheStory = () => (
  <Wrapper>
    <Helmet>
      <title>{translate("navigation.menu.the_story")}</title>
    </Helmet>
    <Image src={HeroImage} />
    <Content>
      <Container animate>
        <Card>
          <CardSection>
            <Stack>
              <Heading type="title1">Die Geschichte der Lipizzaner</Heading>
              <Text>
                Eine kurze Zusammenfassung für den neuen Lipizzanerfreund….{" "}
                <br />
                <br />
                Es war einmal…. <br />
                <br />
                Der Erzherzog KARL II von HABSBURG, Herr über die Steiermark,
                Kärnten, Krain, Istrien und Triest. Er hat in seinen Ländern die
                Pferdezucht, eine der Grundlagen der wirtschaftlichen
                Entwicklung und der militärischen Stärke des Staates, sehr
                gefördert. <br />
                <br />
                Für den Bedarf des eigenen Hofes hat der Erzherzog im Karst, in
                der Nähe der Stadt Triest, auf dem Gestüt LIPICA, dass er vom
                Bischof von Triest, Nikolaus von Coreta erstanden hatte, 1580
                ein Gestüt gegründet. <br />
                <br />
                Er dürfte gewusst haben, dass auf diesem Gebiet bereits von je
                her die Pferdezucht geblüht hat. <br />
                <br />
                Am 19 MAI 1580 wird der Vertrag zur Besitzübernahme
                unterschrieben. <br />
                <br />
                Bereits im ersten Jahr sind aus Spanien drei Zuchthengste und im
                darauffolgenden Jahr weitere drei Zuchthengste und 24 Stuten
                nach Lipica gebracht worden. Zunächst war man sich gar nicht so
                sicher, ob die ersten spanischen Pferde den Umweltbedingungen
                der Karster Region gewachsen sein würden. Doch durch die harten
                Aufzuchtsbedingungen und die damit strenge Selektion,
                entwickelte sich ein robuster und harter Pferdetyp. <br />
                <br />
                Gegen Ende des 17. und zu Beginn des 18. Jahrhunderts wurden
                Pferde aus damals hervorragenden Züchtungen verwendet. Diese
                stammten aus der oberitalienischen Polesina, aus dem dänischen
                Gestüt Frederiksborg sowie aus der deutschen Zuchtstätte
                Lippe-Bückeburg und dem böhmischen Hofgestüt Kladrub. <br />
                <br />
                Heute noch blühen die alten SECHS Stämme, die auf folgende
                Stammväter zurückgehen
              </Text>
              <List size="large">
                <ListItem>
                  <Text>
                    der Frederiksborger, Dänemark <br /> PLUTO, Schimmel,
                    geboren 1765
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    der Frederiksborger, Dänemark <br /> PLUTO, Schimmel,
                    geboren 1765
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    der Frederiksborger, Dänemark <br /> PLUTO, Schimmel,
                    geboren 1765
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    der Frederiksborger, Dänemark <br /> PLUTO, Schimmel,
                    geboren 1765
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    der Frederiksborger, Dänemark <br /> PLUTO, Schimmel,
                    geboren 1765
                  </Text>
                </ListItem>
                <ListItem>
                  <Text>
                    der Frederiksborger, Dänemark <br /> PLUTO, Schimmel,
                    geboren 1765
                  </Text>
                </ListItem>
              </List>
              <Text>
                Es existieren noch zwei jüngere Hengststämme, die in jüngster
                Zeit nachträglich für die Lipizzanerzucht anerkannt wurden.{" "}
                <br /> <br />
                TULIPAN - Ursprung Kroatien <br />
                INCITATO - Ursprung Ungarn <br /> <br />
                Eine detailierte Beschreibung der Geschichte der Lipizzaner
                können sie unter dem folgenden Link nachlesen:
              </Text>
              <Link
                href="http://www.lipica.org/de/entdecken-sie-lipica/geschichte"
                target="_blank"
                rel="noreferrer"
              >
                Lipica geschichte
              </Link>
            </Stack>
          </CardSection>
        </Card>
      </Container>
    </Content>
  </Wrapper>
);

export default TheStory;
