import React, { useCallback } from "react";
import { useCookies } from "react-cookie";
import Modal, { ModalSection } from "@kiwicom/orbit-components/lib/Modal";
import {
  Heading,
  Text,
  Button,
  List,
  ListItem,
  Stack,
} from "@kiwicom/orbit-components/lib";

// Utils
import translate from "../../utils/translate";

const ConsentModal = () => {
  const [, setCookie] = useCookies(["cookieConsent"]);

  const handleCookieConsent = useCallback(() => {
    setCookie("cookieConsent", true, { path: "/" });
  }, [setCookie]);

  return (
    <Modal>
      <ModalSection>
        <Stack>
          <Heading>{translate("cookie_consent.title")}</Heading>
          <Text>{translate("cookie_consent.text")}</Text>
          <List>
            <ListItem>language</ListItem>
          </List>
        </Stack>
      </ModalSection>
      <ModalSection suppressed>
        <Button onClick={handleCookieConsent}>
          {translate("cookie_consent.accept")}
        </Button>
      </ModalSection>
    </Modal>
  );
};

export default ConsentModal;
