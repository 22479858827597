import React from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardSection,
  Stack,
  Text,
  Box,
  Button,
  Heading,
} from "@kiwicom/orbit-components";

import IntroBlog from "../../assets/images/kontakt.jpg";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import translate from "../../utils/translate";
// Icons
import WhatAppIcon from "../../assets/icons/whatsapp.png";
import FacebookIcon from "../../assets/icons/facebook.png";

// Styles
import { Content, Image, Icon, GoogleMap } from "./Styled";

const Contact = () => (
  <Wrapper>
    <Helmet>
      <title>{translate("navigation.menu.contact")}</title>
    </Helmet>
    <Content>
      <Container animate>
        <Stack
          align="center"
          justify="center"
          direction="column"
          spacing="XXLarge"
        >
          <Stack
            direction="column"
            align="center"
            spacing="XXLarge"
            tablet={{ direction: "row", align: "center", justify: "center" }}
          >
            <Image src={IntroBlog} />
            <Stack
              spacing="XSmall"
              justify="center"
              direction="column"
              align="center"
              inline
              tablet={{ align: "start", justify: "start" }}
            >
              <Heading as="h2" align="center" type="display">
                Angelika Pristov
              </Heading>
              <Heading as="h4" align="center" type="displaySubtitle">
                univ.b.sc.agr
              </Heading>
            </Stack>
          </Stack>
          <Card>
            <CardSection>
              <Stack direction="row" justify="between">
                <Text weight="bold" uppercase>
                  {translate("contact.phone_number")}
                </Text>
                <Text>00386 68 129 392</Text>
              </Stack>
            </CardSection>
            <CardSection>
              <Stack direction="row" justify="between">
                <Text weight="bold" uppercase>
                  {translate("contact.email")}
                </Text>
                <Text>info@hof-der-lipizzaner.com</Text>
              </Stack>
            </CardSection>
            <CardSection>
              <Stack direction="row" justify="between">
                <Text weight="bold" uppercase>
                  {translate("contact.address")}
                </Text>
                <Box direction="column">
                  <Text align="right">
                    BIŠ 40, 2254 TRNOVSKA VAS, SLOWENIEN
                  </Text>
                  <Text align="right" type="secondary">
                    {translate("contact.we_are_80km_from_graz")}
                  </Text>
                </Box>
              </Stack>
            </CardSection>
            <CardSection>
              <Stack direction="row" justify="between">
                <Text weight="bold" uppercase>
                  {translate("contact.contact_us")}
                </Text>
                <a href="mailto:angelikapristov@yahoo.de?subject=Contact from Hof der Lipizzaner">
                  <Button> {translate("contact.send_an_email")}</Button>
                </a>
              </Stack>
            </CardSection>
            <CardSection>
              <Stack direction="row" spacing="large" wrap>
                <Stack direction="row" align="center" inline grow={false}>
                  <Icon src={WhatAppIcon} alt="WhatsApp" title="WhatsApp" />
                  <Text size="large">00386 68 129 392</Text>
                </Stack>
                <a
                  href="https://www.facebook.com/hofderlipizzaner"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Stack direction="row" align="center" inline grow={false}>
                    <Icon src={FacebookIcon} alt="Facebook" title="Facebook" />
                    <Text size="large">Hof der Lipizzaner</Text>
                  </Stack>
                </a>
              </Stack>
            </CardSection>
          </Card>
          <Heading>{translate("contact.our_home")}</Heading>
          <GoogleMap src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2744.4137699969006!2d15.87212211585772!3d46.53950116906919!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x476f6fc76bb3f7f7%3A0x20ae25c28a29feaf!2sKmetija%20Lipican!5e0!3m2!1sen!2scz!4v1650569452016!5m2!1sen!2scz" />
        </Stack>
      </Container>
    </Content>
  </Wrapper>
);

export default Contact;
