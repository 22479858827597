import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  margin-top: -130px;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const Image = styled.img`
  width: 100%;
  height: 40vh;
  object-fit: cover;
`;

export const ContentImage = styled.img`
  width: 100%;
  min-height: 400px;
  object-fit: cover;
  margin: 16px 16px 0 0;

  border-radius: 10px;
  border: 4px solid rgb(138, 23, 26);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
`;

export const Link = styled.a`
  font-size: 24px;
  color: rgb(138, 23, 26);
`;
