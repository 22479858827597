import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import { format } from "date-fns";
import { useLocation } from "react-router-dom";
import {
  Card,
  CardSection,
  Stack,
  Text,
  Box,
  Button,
  Heading,
  Loading,
} from "@kiwicom/orbit-components";
import { ChevronLeft } from "@kiwicom/orbit-components/lib/icons";

// Utils
import { getBlogById, getBlogImagesById } from "../../utils/api/blog";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Types
import { Blog as BlogType } from "../../static/types/blog";
import { Image as ImageType } from "../../static/types/image";
// Styles
import { WysiwygContent } from "../../components/Wysiwyg/Styled";
// Utils
import translate from "../../utils/translate";

import { Content, ContentImage, BackToCatalog } from "./Styled";

const BlogPost = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [post, setPosts] = useState<BlogType | null>(null);
  const [images, setImages] = useState<ImageType[]>([]);
  const { pathname } = useLocation();

  const blogId = pathname.split("/")[2];

  useEffect(() => {
    if (blogId) {
      // Fetchs blog post
      getBlogById(Number(blogId), setPosts, setLoading);
      // Fetchs blog post images
      getBlogImagesById(Number(blogId), setImages, setLoading);
    }
  }, [blogId]);

  return (
    <Wrapper>
      <Helmet>
        <title>{post?.title}</title>
      </Helmet>
      <Content>
        {loading || !post ? (
          <Container animate>
            <Loading type="pageLoader" />
          </Container>
        ) : (
          <Container animate>
            <Stack>
              <Box
                padding={{ left: "medium", right: "medium" }}
                tablet={{ padding: { left: "none", right: "none" } }}
              >
                <Heading as="h1">{post.title}</Heading>
                <Text type="secondary">
                  {format(new Date(post.created_at), "PPP")}
                </Text>
              </Box>
              <Card>
                <CardSection>
                  <WysiwygContent
                    dangerouslySetInnerHTML={{ __html: post.content }}
                  />
                </CardSection>
                {images.length > 0 && (
                  <CardSection>
                    {images.map((image: ImageType) => (
                      <ContentImage
                        src={`${process.env.REACT_APP_STORAGE_BASE}/blog/${image.path}`}
                        alt={image.title}
                      />
                    ))}
                  </CardSection>
                )}
              </Card>
              <Box
                width="100%"
                padding={{ left: "medium", right: "medium" }}
                tablet={{ padding: { left: "none", right: "none" } }}
              >
                <BackToCatalog
                  href={post.type === "reference" ? "/references" : "/blog"}
                >
                  <Button type="white" iconLeft={<ChevronLeft />}>
                    <Stack direction="row">{translate("blog.back")}</Stack>
                  </Button>
                </BackToCatalog>
              </Box>
            </Stack>
          </Container>
        )}
      </Content>
    </Wrapper>
  );
};

export default BlogPost;
