import React from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardSection,
  Stack,
  Text,
  Heading,
} from "@kiwicom/orbit-components";

// Images
import UnsereSecondImage from "../../assets/images/unsere-2.jpg";
import UnsereThirdImage from "../../assets/images/unsere-3.jpg";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import translate from "../../utils/translate";

// Styles
import { Content, Image, ContentImage } from "./Styled";

const BreedingLipizzans = () => (
  <Wrapper>
    <Helmet>
      <title>{translate("navigation.menu.our_lipizzans")}</title>
    </Helmet>
    <Image
      src={`${process.env.REACT_APP_API_BASE}/images/our-lipizzans-banner.jpg`}
    />
    <Content>
      <Container animate>
        <Card>
          <CardSection>
            <Stack>
              <Heading type="title1">
                {translate("navigation.menu.our_lipizzans")}
              </Heading>
              <Text>
                Die Lipizzanerzucht ist ein Werk von Generationen. <br />
                <br />
                Unsere Zuchtstuten stammen aus alten historischen Linien aus dem
                Staatsgestüt Lipica ab. Wir züchten ausschliesslich mit
                gesundheitlich einwandfreien Zuchtstuten. <br />
                <br />
                Alle Deckhengste stammen ebenfalls aus dem Staatsgestüt Lipica
                und alle Anpaarungen basieren auf verantwortungsvollen gesunden
                Linien, gehalten mit kleinen Inzuchtfaktor. <br />
                <br />
                Unsere Zuchtziele sind klar gegeben: <br />
                <br />
                Die Erhaltung des reinrassigen Lipizzaners, gemäß den
                traditionellen Zuchtregeln im Typ des barocken Pferdes. Im
                Gesamtbild ein haromisches, ausdruckvolles Pferd mit adeliger
                Haltung. <br />
                <br />
                Das Hauptgestüt Lipica gewährleistet die Identifizierung und
                ihren Eintrag ins Internationale Register. Die
                Reinheitsgewährleistung erfolgt durch gendiagnostische
                Abstammungskontrollen. <br />
                <br />
                Ganz wichtig und darauf legen wir höchsten Wert, ist die
                artgerechte Fohlen, - Jungpferdeaufzucht: <br />
                <br />
                Da Lipizzaner Spätentwickler sind, trennen wir unsere
                Lipizzanerfohlen nicht bereits mit 6 Monaten wie üblich, sondern
                erst mit 9-10 Monaten von der Mutter <br />
                <br />
                Alle unsere Jünglinge wachsen mit Gleichaltrigen artgerecht im
                Offenstall auf. <br />
                <br />
                Sie haben viel Auslauf auf großen Weiden was für konstantes,
                gleichmäßiges Wachstum mit starken Knochen und Gelenken sorgt.{" "}
                <br />
                <br />
                Gut sozialisiert im Herdenverband mit mehreren Stuten und Fohlen
                und dem täglichen Umgang zum Menschen, legen den Grundstein für
                ein langes, gesundes Pferdeleben <br />
                <br />
                Unsere ZUCHTSTUTEN:
              </Text>
              <Stack>
                <ContentImage src={UnsereThirdImage} />
                <ContentImage src={UnsereSecondImage} />
                <ContentImage
                  src={`${process.env.REACT_APP_API_BASE}/images/our-lipizzans-image-1.jpg`}
                />
                <ContentImage
                  src={`${process.env.REACT_APP_API_BASE}/images/our-lipizzans-image-2.jpg`}
                />
                <Heading>Trofetta</Heading>
                <ContentImage
                  src={`${process.env.REACT_APP_API_BASE}/images/our-lipizzans-image-3.jpg`}
                />
              </Stack>
            </Stack>
          </CardSection>
        </Card>
      </Container>
    </Content>
  </Wrapper>
);

export default BreedingLipizzans;
