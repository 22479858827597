import axios from "axios";

export const getHorses = async (setData: Function, setLoading?: Function) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/horse`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getRepnikHorses = async (
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/horse-repnik`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getHorseById = async (
  id: number,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/horse/${id}`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getHorseImagesById = async (
  id: number,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/images/type/horse/${id}`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};
