import styled from "styled-components";

export const WysiwygContent = styled.div`
  del {
    text-decoration: line-through;
  }

  strong {
    font-weight: 900;
  }

  a {
    color: rgb(138, 23, 26);
  }

  p {
    min-height: 20px;
  }

  ins {
    text-decoration: underline;
  }
`;
