import React from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardSection,
  Stack,
  Text,
  Box,
  Heading,
} from "@kiwicom/orbit-components";

import HeroImage from "../../assets/images/description-hero.jpg";
import DescriptionImage from "../../assets/images/description.jpg";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import translate from "../../utils/translate";

// Styles
import { Content, Image, ContentImage } from "./Styled";

const BreedDescription = () => (
  <Wrapper>
    <Helmet>
      <title>{translate("navigation.menu.race_description")}</title>
    </Helmet>
    <Image src={HeroImage} />
    <Content>
      <Container animate>
        <Card>
          <CardSection>
            <Stack>
              <Heading type="title1">
                {translate("navigation.menu.race_description")}
              </Heading>
              <Text>
                Der Lipizzaner ist ein ausdrucksvolles Pferd mit adeliger
                Haltung und einem hervorragenden Gesamtbild nach den Idealen des
                Barocks. <br />
                <br />
                Waren die Stammväter,- und Mütter kunterbunt, entschied man sich
                in der Donaumonarchie beim Lipizzaner für WEISS. * kleine
                Ausnahmen bestätigen heute die Regel :) <br />
                <br />
                Rassetypische Merkmale des Lipizzaners
              </Text>
              <Box direction="row">
                <ContentImage src={DescriptionImage} />
              </Box>
              <Text>
                <strong>DAS AUGE:</strong> <br /> groß, dunkel und
                ausdrucksstark <br />
                <br />
                <strong>DER KOPF</strong> <br /> imposanter, trockener,
                vehältnismässiger grosser Kopf, gelegentlich auch Ramskopf. Die
                konkave Kopfform ist unerwünscht und untypisch. <br />
                <br />
                <strong>DIE BRUST</strong> <br />
                breit und tief <br />
                <br />
                <strong>DIE BEINE</strong> <br />
                relativ kurze Gliedmassen, lassen gut ausgebildete Sehnen und
                Bänder erkennen <br />
                <br />
                <strong>DIE HUFE</strong> <br />
                klein und hart <br />
                <br />
                <strong>DIE GRÖSSE</strong> <br />
                zwischen 150cm - 160cm +/- <br />
                <br />
                <strong>DER HALS</strong> <br />
                gewölbter, kräftiger Hals, hoch angesetzt <br />
                <br />
                <strong>DER WIDERRIST</strong> <br />
                selten ausgeprägt <br />
                <br />
                <strong>DIE KRUPPE</strong> <br />
                muskulöse Kruppe, nicht abgeschlagen <br />
                <br />
                <strong>DER RÜCKEN</strong> <br />
                *kurz und kräftig oder *lang und vorgetieft oder *lang und
                gerade (siehe Fahrtypen) <br />
                <br />
                Da das Spektrum der Rasse so breit ist, ist die
                Merkmalsbeschreibung nur ein Anhaltungspunkt und dient zur
                Orientierung. Ich kenne so viele Lipizzaner, die davon
                abweichen, aber keineswegs ihre Schönheit verbergen müssen. Und
                genau diese Vielfalt fasziniert und lässt oft die historischen
                Ahnen durchblicken.
              </Text>
            </Stack>
          </CardSection>
        </Card>
      </Container>
    </Content>
  </Wrapper>
);

export default BreedDescription;
