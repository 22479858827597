import axios from "axios";

export const getBlogPosts = async (
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/blog`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getReferencePosts = async (
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/reference`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getBlogById = async (
  id: number,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/blog/${id}`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};

export const getBlogImagesById = async (
  id: number,
  setData: Function,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    const { data } = await axios.get(
      `${process.env.REACT_APP_API_BASE}/api/images/type/blog/${id}`
    );
    // Set state
    setData(data);
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};
