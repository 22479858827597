import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ThemeProvider from "@kiwicom/orbit-components/lib/ThemeProvider";
import getTokens from "@kiwicom/orbit-components/lib/getTokens";
import { useCookies } from "react-cookie";

// Components
import Navigation from "./components/Navigation";
import Footer from "./components/Footer";
// Views
import HomeView from "./views/Home/Home";
import CatalogView from "./views/Catalog/Catalog";
import ContactView from "./views/Contact/Contact";
import BlogView from "./views/Blog/Blog";
import BreedingLipizzansView from "./views/BreedingLipizzans/BreedingLipizzans";
import RaceDescriptionView from "./views/RaceDescription/RaceDescription";
import TheStoryView from "./views/TheStory/TheStory";
import LinksView from "./views/Links/Links";
import HorseProfileView from "./views/HorseProfile/HorseProfile";
import BlogPostView from "./views/BlogPost/BlogPost";
import ReferenceView from "./views/References/References";
import RepnikView from "./views/Repnik/Repnik";
import ConsentModal from "./components/ConsentModal/ConsentModal";

const customTokens = getTokens({
  palette: {
    product: {
      light: "#F0A04C",
      lightHover: "#D69045",
      lightActive: "#BA7D3C",
      normal: "#8A171A",
      normalHover: "#7c1417",
      normalActive: "#6e1214",
      dark: "#6e1214",
    },
  },
  base: {
    borderRadius: "10px",
    fontFamily: "Roboto Slab",
  },
});

function App() {
  const [{ cookieConsent }] = useCookies(["cookieConsent"]);

  return (
    <ThemeProvider theme={{ orbit: customTokens }}>
      <Router>
        <>
          <Navigation />

          {!cookieConsent && <ConsentModal />}

          <Switch>
            <Route path="/" exact>
              <HomeView />
            </Route>
            <Route path="/catalog" exact>
              <CatalogView />
            </Route>
            <Route path="/catalog/:id" exact>
              <HorseProfileView />
            </Route>
            <Route path="/contact" exact>
              <ContactView />
            </Route>
            <Route path="/blog" exact>
              <BlogView />
            </Route>
            <Route path="/blog/:id" exact>
              <BlogPostView />
            </Route>
            <Route path="/our-lipizzans" exact>
              <BreedingLipizzansView />
            </Route>
            <Route path="/race-description" exact>
              <RaceDescriptionView />
            </Route>
            <Route path="/the-story" exact>
              <TheStoryView />
            </Route>
            <Route path="/links" exact>
              <LinksView />
            </Route>
            <Route path="/references" exact>
              <ReferenceView />
            </Route>
            <Route path="/references/:id" exact>
              <BlogPostView />
            </Route>
            <Route path="/repnik" exact>
              <RepnikView />
            </Route>
          </Switch>

          <Footer />
        </>
      </Router>
    </ThemeProvider>
  );
}

export default App;
