import styled from "styled-components";

export const Wrapper = styled.footer`
  width: 100%;
  display: flex;
  padding: 48px 16px;
  background-color: rgb(138, 23, 26);
  color: white;
`;

export const Bottom = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  padding-top: 16px;
  margin-top: 32px;

  span {
    color: white;
  }
`;

export const LogoWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0;
`;

export const Logo = styled.img`
  max-height: 100px;
  object-fit: contain;
`;

export const LogoLink = styled.a`
  margin: 0 24px;
  opacity: 0.6;
  transition: opacity 0.3s ease-in-out;

  &:hover {
    opacity: 1;
  }
`;
