import React, { useState } from "react";
import { Button, Hide } from "@kiwicom/orbit-components";

// Assets
import LogoIcon from "../../assets/logo-slim.jpg";
import MenuIcon from "../../assets/icons/menu.png";
import CloseIcon from "../../assets/icons/close.svg";
// Components
import LanguagePicker from "../LanguagePicker";
import { Container } from "../Content/styles";
// Utils
import translate from "../../utils/translate";

// Styles
import { Wrapper, Menu, Branding, Actions, Close, MenuButton } from "./styles";

const Navigation = () => {
  const [menuActive, setMenuActive] = useState(false);

  return (
    <>
      <Wrapper>
        <Container directionRow spaceBetween>
          <Branding>
            <a href="/">
              <img src={LogoIcon} alt="Hof der Lipizzaner" />
            </a>
          </Branding>
          <Actions>
            <LanguagePicker />
            <Hide on={["mediumMobile", "smallMobile"]}>
              <a href="/catalog">
                <Button type="primarySubtle" size="normal">
                  {translate("navigation.menu.catalog")}
                </Button>
              </a>
            </Hide>
            <MenuButton>
              <Button
                type="secondary"
                size="normal"
                onClick={() => setMenuActive(true)}
              >
                <img src={MenuIcon} alt="Menu" />
              </Button>
            </MenuButton>
          </Actions>
        </Container>
      </Wrapper>
      {menuActive && (
        <Menu>
          <Close onClick={() => setMenuActive(false)}>
            <img src={CloseIcon} alt="Close" />
          </Close>
          <ul onClick={() => setMenuActive(false)}>
            <li>
              <a href="/">{translate("navigation.menu.home")}</a>
            </li>
            <li>
              <a href="/blog">{translate("navigation.menu.blog")}</a>
            </li>
            <li>
              <a href="/references">
                {translate("navigation.menu.references")}
              </a>
            </li>
            <li>
              <a href="/our-lipizzans">
                {translate("navigation.menu.our_lipizzans")}
              </a>
            </li>
            <li>
              <a href="/race-description">
                {translate("navigation.menu.race_description")}
              </a>
            </li>
            <li>
              <a href="/the-story">{translate("navigation.menu.the_story")}</a>
            </li>
            <li>
              <a href="/repnik">
                {translate("navigation.menu.fahrstall_repnik")}
              </a>
            </li>
            <li>
              <a href="/contact">{translate("navigation.menu.contact")}</a>
            </li>
            <li>
              <a href="/links">{translate("navigation.menu.links")}</a>
            </li>
            <li>
              <a
                href={`${process.env.REACT_APP_API_BASE}/docs/privacy-policy.pdf`}
                target="_blank"
                rel="noreferrer"
              >
                {translate("navigation.menu.privacy_policy")}
              </a>
            </li>
            <li>
              <a href="/catalog">{translate("navigation.menu.catalog")}</a>
            </li>
          </ul>
        </Menu>
      )}
    </>
  );
};

export default Navigation;
