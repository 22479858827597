import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { Carousel } from "react-responsive-carousel";
import {
  Card as OrbitCard,
  CardSection,
  Heading,
  InputField,
  Button,
  Stack,
  Box,
  Text,
  Loading,
} from "@kiwicom/orbit-components";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Utils
import { submitNewsletter } from "../../utils/api/newsletter";
import { validateEmail } from "../../utils/email";
// Other
import Logo from "../../assets/lipizzaner-logo-transparent.png";
// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import translate from "../../utils/translate";

// Styles
import {
  Content,
  CarouselItem,
  Overlay,
  Cards,
  Card,
  CardOverlay,
} from "./Styled";

const Home = () => {
  const [newsletter, setNewsletter] = useState("");
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [validation, setValidation] = useState<string | null>(null);

  const handleNewsletterChange = (
    e: React.SyntheticEvent<HTMLInputElement>
  ) => {
    // @ts-expect-error
    const { value } = e.target;
    setNewsletter(value);
  };

  const handleNewsletterSubmit = () => {
    const isEmailValid = validateEmail(newsletter);
    if (isEmailValid) {
      submitNewsletter(newsletter, setLoading);
      setNewsletter("");
      setSuccess(true);
    } else {
      setValidation("Email is not valid.");
    }
  };

  return (
    <Wrapper>
      <Helmet>
        <title>{translate("page.title.home")}</title>
      </Helmet>
      <Carousel
        autoPlay
        infiniteLoop
        showStatus={false}
        showIndicators={false}
        showThumbs={false}
        swipeable={false}
        interval={6000}
        showArrows={false}
      >
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-3.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-1.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-2.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-4.jpeg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-5.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-6.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-7.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-8.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-9.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-10.jpg`}
        />
        <CarouselItem
          backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-11.jpg`}
        />
      </Carousel>
      <Overlay>
        <img src={Logo} alt="Hof der Lipizzaner" />
        <Heading type="title1" align="center" as="h2" inverted>
          {translate("home.warm_welcome")}
        </Heading>
        <Heading
          type="title2"
          align="center"
          as="h3"
          spaceAfter="medium"
          inverted
        >
          {translate("home.to_the")}
        </Heading>
        <Heading type="display" align="center" as="h1" inverted>
          {translate("home.hof_der_lipizzaner")}
        </Heading>
      </Overlay>
      <Content>
        <Container animate>
          <Stack spacing="XXLarge">
            <OrbitCard>
              <CardSection>
                <Text size="large">
                  Es freut mich sehr, dass Sie uns besuchen und ich Ihnen unser
                  Leben mit unseren Lipizzanern vorstellen darf.
                  <br />
                  <br />
                  Ich bin Angelika und lebe zusammen mit meinem Mann Luka,
                  meinen Söhnen und meinen Lipizzanern in Slowenien auf unserem
                  Hof der LIPIZZANER. Unser Hof liegt idyllisch in der
                  slowenischen Steiermark und ist unser Lebenstraum.
                  <br />
                  <br />
                  Wir züchten aus ganzem Herzen diese wundervolle, historische
                  Rasse und bieten professionelle Vermittlung von Lipizzanern
                  an.
                  <br />
                  <br />
                  Züchten heißt Denken in Generationen und wir züchten
                  ausschließlich reine LIF registrierte Lipizzaner.
                  <br />
                  <br />
                  Hier bei uns werden Menschlichkeit und Pferdefreundlichkeit
                  ganz groß geschrieben.
                  <br />
                  <br />
                  Nutzen Sie unsere Zuchterfahrung und unsere Kontakte um ihren
                  TRAUMLIPIZZANER zu finden.
                </Text>
              </CardSection>
            </OrbitCard>
            <Cards>
              <Card
                backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-4.jpeg`}
              >
                <CardOverlay href="/our-lipizzans">
                  {translate("navigation.menu.our_lipizzans")}
                </CardOverlay>
              </Card>
              <Card
                backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-3.jpg`}
              >
                <CardOverlay href="/catalog">
                  {translate("navigation.menu.catalog")}
                </CardOverlay>
              </Card>
              <Card
                backgroundImage={`${process.env.REACT_APP_API_BASE}/images/carousel-6.jpg`}
              >
                <CardOverlay href="/blog">
                  {translate("navigation.menu.blog")}
                </CardOverlay>
              </Card>
            </Cards>
            <OrbitCard>
              <CardSection>
                <Box padding={{ left: "medium", right: "medium" }}>
                  <Stack align="center" direction="column" spacing="XLarge">
                    {success && !loading && (
                      <Stack spacing="XXSmall">
                        <Heading type="display" align="center">
                          Thank you!
                        </Heading>
                        <Heading type="displaySubtitle" align="center">
                          you are now subscribed to our Newsletter!
                        </Heading>
                      </Stack>
                    )}
                    {!success && (
                      <Stack spacing="XXSmall">
                        <Heading type="display" align="center">
                          {translate("home.subscribe_to_our_newsletter")}
                        </Heading>
                        <Heading type="displaySubtitle" align="center">
                          {translate("home.receive_updates")}
                        </Heading>
                      </Stack>
                    )}
                    {loading && <Loading type="pageLoader" />}
                    {!success && !loading && (
                      <Stack spacing="small" align="center" direction="column">
                        <InputField
                          placeholder={translate("home.your_email")}
                          value={newsletter}
                          onChange={handleNewsletterChange}
                          error={validation}
                          type="email"
                          width="300px"
                        />
                        <Button
                          type="secondary"
                          onClick={handleNewsletterSubmit}
                        >
                          {translate("home.submit")}
                        </Button>
                      </Stack>
                    )}
                  </Stack>
                </Box>
              </CardSection>
            </OrbitCard>
          </Stack>
        </Container>
      </Content>
    </Wrapper>
  );
};

export default Home;
