import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const HorsePriceImage = styled.img`
  width: 30px;
  height: 30px;
  object-fit: contain;
`;

export const SearchBox = styled.div`
  background-color: white;
  border-radius: 10px;
  padding: 32px;

  font-weight: 600;
  font-size: 16px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
`;

export const Cards = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 24px;

  ${media.tablet(css`
    grid-template-columns: repeat(2, 1fr);
  `)}

  ${media.desktop(css`
    grid-template-columns: repeat(3, 1fr);
  `)}
`;

export const CardOverlay = styled.a`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px;
  margin: auto;

  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  font-size: 24px;
  font-weight: 700;
`;

export const Card = styled.div<{
  backgroundImage: unknown;
}>`
  width: 100%;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;

  border-radius: 0;
  background-image: url(${({ backgroundImage }: any) => backgroundImage});
  background-position: top;
  background-size: cover;
  transition: transform 0.3s ease-in-out;
  border: 4px solid white;
  // rgb(138, 23, 26)
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

  ${media.tablet(css`
    border-radius: 10px;
  `)}

  > a {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover {
    > a {
      opacity: 1;
    }
  }
`;
