// Translate util
// eslint-disable-next-line import/extensions
import translations from "../static/translations.json";

function translate(tKey: string): string {
  const defaultLanguage = "de";
  const language =
    document.cookie.match(`(^|;)\\s*language\\s*=\\s*([^;]+)`)?.pop() ||
    defaultLanguage;

  // @ts-ignore
  const selectedKey = translations[tKey];

  if (!selectedKey) return tKey;

  return selectedKey[language];
}

export default translate;
