import styled, { css } from "styled-components";
import media from "@kiwicom/orbit-components/lib/utils/mediaQuery";

export const Content = styled.div`
  z-index: 3;
  padding: 48px 0px;

  ${media.tablet(css`
    padding: 48px 16px;
  `)}
`;

export const BlogLink = styled.a`
  &:hover {
    h3 {
      color: rgb(138, 23, 26) !important;
    }
  }
`;

export const BlogImage = styled.div`
  width: 100%;
  height: 180px;
  border-radius: 10px;
  background-image: url(${({ url }: { url: string }) => url});
  background-size: cover;
  background-position: center;

  ${media.tablet(css`
    width: 110px;
    height: 110px;
  `)}
`;
