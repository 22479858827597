import React from "react";

// Styles
import { Wrapper } from "./styles";

type Props = {
  children: any;
};

const Content = ({ children }: Props) => {
  return <Wrapper>{children}</Wrapper>;
};

export default Content;
