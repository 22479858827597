import axios from "axios";

export const submitNewsletter = async (
  email: string,
  setLoading?: Function
) => {
  try {
    setLoading && setLoading(true);
    await axios.post(`${process.env.REACT_APP_API_BASE}/api/newsletter`, {
      email,
    });
    setLoading && setLoading(false);
  } catch (error) {
    console.error(error);
  }
};
