import React from "react";
import Helmet from "react-helmet";
import {
  Card,
  CardSection,
  Stack,
  Text,
  List,
  ListItem,
  Box,
  Button,
  Heading,
} from "@kiwicom/orbit-components";

// Components
import Wrapper from "../../components/Content";
import { Container } from "../../components/Content/styles";
// Utils
import translate from "../../utils/translate";

// Styles
import { Content, Link, ButtonLink } from "./Styled";

const Blog = () => (
  <Wrapper>
    <Helmet>
      <title>{translate("navigation.menu.links")}</title>
    </Helmet>
    <Content>
      <Container animate>
        <Stack>
          <Box
            padding={{ left: "medium", right: "medium" }}
            tablet={{ padding: { left: "none", right: "none" } }}
          >
            <Heading as="h1">{translate("navigation.menu.links")}</Heading>
          </Box>
          <Stack spacing="XXLarge">
            <Stack direction="column" tablet={{ direction: "row" }}>
              <Card>
                <CardSection
                  header={
                    <Text size="large" type="secondary">
                      {translate("links.state_stud")}
                    </Text>
                  }
                >
                  <List>
                    <ListItem>
                      <Link target="_blank" href="http://www.lipica.org/de/">
                        Hauptgestüt LIPICA
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.srs.at/">
                        Gestüt PIBER
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        target="_blank"
                        href="http://ergela-djakovo.hr/de/startseite/"
                      >
                        Gestüt DAKOVO
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.menesgasdasag.hu/">
                        Gestüt SVILVASVARAD
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.nztopolcianky.sk/">
                        Gestüt TOPOLCIANKY
                      </Link>
                    </ListItem>
                  </List>
                </CardSection>
              </Card>
              <Card>
                <CardSection
                  header={
                    <Text size="large" type="secondary">
                      {translate("links.lipizzaner_breeding_associations")}
                    </Text>
                  }
                >
                  <List>
                    <ListItem>
                      <Link target="_blank" href="http://www.lipica.org/de/">
                        Hauptgestüt LIPICA
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.srs.at/">
                        Gestüt PIBER
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        target="_blank"
                        href="http://ergela-djakovo.hr/de/startseite/"
                      >
                        Gestüt DAKOVO
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.menesgasdasag.hu/">
                        Gestüt SVILVASVARAD
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.nztopolcianky.sk/">
                        Gestüt TOPOLCIANKY
                      </Link>
                    </ListItem>
                  </List>
                </CardSection>
              </Card>
              <Card>
                <CardSection
                  header={
                    <Text size="large" type="secondary">
                      {translate("links.private_lipizzaner_breeder")}
                    </Text>
                  }
                >
                  <List>
                    <ListItem>
                      <Link target="_blank" href="http://www.lipica.org/de/">
                        Hauptgestüt LIPICA
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.srs.at/">
                        Gestüt PIBER
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link
                        target="_blank"
                        href="http://ergela-djakovo.hr/de/startseite/"
                      >
                        Gestüt DAKOVO
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.menesgasdasag.hu/">
                        Gestüt SVILVASVARAD
                      </Link>
                    </ListItem>
                    <ListItem>
                      <Link target="_blank" href="http://www.nztopolcianky.sk/">
                        Gestüt TOPOLCIANKY
                      </Link>
                    </ListItem>
                  </List>
                </CardSection>
              </Card>
            </Stack>
            <Box
              align="center"
              textAlign="center"
              padding={{ left: "medium", right: "medium" }}
            >
              <ButtonLink
                target="_blank"
                href="http://www.lipizzan-online.com/"
              >
                <Button size="large">
                  *** LIPIZZAN INTERNATIONAL FEDERATION - LIF ***
                </Button>
              </ButtonLink>
            </Box>
          </Stack>
        </Stack>
      </Container>
    </Content>
  </Wrapper>
);

export default Blog;
