import React from "react";
import { useCookies } from "react-cookie";

// Flags
import UnitedKingdomFlag from "../../assets/flags/united-kingdom.png";
import UnitedArabEmiratedFlag from "../../assets/flags/united-arab-emirates.png";
import GermanFlag from "../../assets/flags/germany.png";

// Styles
import { Wrapper, Flag } from "./styles";

const LanguagePicker = () => {
  const [, setCookie] = useCookies(["language"]);

  const handleSetCookie = (lang: string) => {
    setCookie("language", lang, { path: "/" });
    window.location.reload();
  };

  return (
    <Wrapper>
      <Flag
        onClick={() => handleSetCookie("de")}
        src={GermanFlag}
        alt="German flag"
      />
      <Flag
        onClick={() => handleSetCookie("en")}
        src={UnitedKingdomFlag}
        alt="United kingdom flag"
      />
      <Flag
        onClick={() => handleSetCookie("ar")}
        src={UnitedArabEmiratedFlag}
        alt="United arab emirates flag"
      />
    </Wrapper>
  );
};

export default LanguagePicker;
